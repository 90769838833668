import Asset from "@components/CustomTypography/Asset/Asset"
import Content, {
	ContentType,
} from "@components/CustomTypography/Content/Content"
import ButtonSwitcher from "@components/Switchers/ButtonSwitcher/ButtonSwitcher"
import {direction} from "@customTypes/customTypes"
import {AdvancedSideAssetMainContent} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"
import React from "react"

import styles from "./ContentAsset.module.scss"

export type ContentAssetNewProps = {
	data?: AdvancedSideAssetMainContent
	direction?: direction
	contentProps?: ContentType
	withAutoSizing?: boolean
	shortDesc?: number
	scale?: {
		width: 4 | 16
		height: 3 | 9
	}
}

const ContentAsset = (props: ContentAssetNewProps) => {
	if (typeof props.data === "undefined") {
		return <></>
	}

	const directionMap = {
		right: styles.right,
		left: styles.left,
	}

	const direction = directionMap[props.direction || "right"] || styles.right

	const assetData = {...props.data.preview}

	return (
		<div className={`${styles.assetContainer} ${direction}`}>
			<div className={styles.content}>
				<Content
					{...props.contentProps}
					data={props.data}
					shortDesc={props.shortDesc}
					size={
						props.contentProps != null
							? props.contentProps.size
							: "M"
					}
				/>
				{props.data.buttons?.length != null &&
					props.data.buttons?.length > 0 && (
						<div className={styles.content_buttons_box}>
							<ButtonSwitcher buttonsList={props.data} />
						</div>
					)}
			</div>
			<div className={styles.asset}>
				<Asset
					data={{
						...assetData,
					}}
					type={
						props.data.preview?.type
							? props.data.preview?.type
							: "string"
					}
				/>
			</div>
		</div>
	)
}

export default ContentAsset
