import Description from "@components/CustomTypography/Description/Description"

import styles from "./PrivateFooterBottom.module.scss"

type Props = {}

const PrivateFooterBottom = (props: Props): any => {
	const year = new Date().getFullYear()
	return (
		<Description
			className={styles.container}
			content={`Dream Broker Ltd. I Copyright ${year} I Keilalahdentie 2-4 FI-02150 Espoo Finland`}
		/>
	)
}

export default PrivateFooterBottom
